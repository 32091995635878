import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { isNewTheme } from "core/model/utils/featureFlags";
import { IconType } from "ds/icons";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, margin } from "ds_legacy/materials/metrics";
import { Title } from "ds_legacy/materials/typography";
import { useTranslations } from "translations";

export function GeneralTableTitle({
  count = 0,
  description,
  filteredCount,
  iconProps,
  title,
  variant = "default",
}: {
  count: number;
  description?: string | JSX.Element;
  filteredCount?: number;
  iconProps: {
    Icon: IconType;
  };
  title: string;
  variant?: "minimal" | "default";
}) {
  const theme = useTheme();
  const translations = useTranslations();
  const displayTitle = `${title} ${
    filteredCount != null
      ? `(${translations.userManagement.numberAccounts({
          numbersearchresults: filteredCount.toString(),
          numberexistingaccounts: count.toString(),
        })})`
      : `(${count})`
  }`;

  if (variant === "minimal") {
    return (
      <VerticalLayout margin={margin(2, 0, count && description ? 1 : 2, 0)}>
        {!!count && (
          <>
            <Title margin="0">{displayTitle}</Title>
            {description && <Description value={description} />}
          </>
        )}
      </VerticalLayout>
    );
  }
  const { Icon } = iconProps;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: dp(30),
            height: dp(30),
            lineHeight: dp(30),
            alignItems: "center",
          }}
        >
          <Icon
            style={{
              color: isNewTheme() ? undefined : theme.palette.primary.main,
              width: dp(18.75),
              height: dp(23.75),
            }}
            width={dp(18.75)}
            height={dp(23.75)}
          />
        </div>
        <Typography
          sx={{
            fontSize: dp(22),
            lineHeight: dp(30),
            fontWeight: 700,
          }}
        >
          {displayTitle}
        </Typography>
      </div>
      {description && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", width: dp(30), height: dp(30) }}></div>
          <Description value={description} />
        </div>
      )}
    </div>
  );
}

function Description({ value }: { value: string | JSX.Element }) {
  return typeof value === "string" ? (
    <Typography variant="body2">{value}</Typography>
  ) : (
    value
  );
}
